.App {
  background-color: #edeefb;
  align-items: center;
  justify-content: center;
  height: 90vh;
  height: 90dvh;
}

.App-header {
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .dashboard-header {
    justify-content: end !important;
  }

  .dashboard-header > .logo-group {
    display: none;
  }
}

.dashboard-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding-top: 0;
}

.dashboard-items > * {
  flex-wrap: wrap;
  flex-basis: 354px;
  flex-shrink: 0;
  min-height: 300px;
  min-width: 317px;
  max-width: 380px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 0;
  padding: 24px;
  padding-right: 16px;
}

@media screen and (min-width: 686px) {
  .dashboard-items {
    justify-content: start;
    padding-right: 40px;
    padding-left: 40px;
  }

  .dashboard-items > * {
    margin-right: 32px;
  }
}

.user-data li {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
}

.fixed-width {
  flex-shrink: 0;
  font-size: 16px;
  line-height: 30px;
  min-width: 78px;
  font-weight: 400;
}

.data-name {
  margin-left: 24px;
  font-weight: 500;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 40px 20px;
}

.qrBox h2 {
  color: #604fed;
  font-size: 1.1rem;
  font-weight: 500;
  padding-top: 3rem;
  padding-bottom: 0.1rem;
}

.qrBox p {
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}
